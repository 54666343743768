
import Sidebar from "./Sidebar.vue"
import Header from "./Header.vue"
import { defineComponent, ref, onMounted, computed } from 'vue';

import axios from '../axios'
import { useStore } from 'vuex';
import { User } from "@/classes/user";

export default defineComponent({
  components: {
    Sidebar,
    Header
  },
  setup() {
    const store = useStore();
  

    const auth_user = computed(() => store.getters["AuthUser/auth_user"]);

    return {
      selectedKeys: ref<string[]>(['0']),
      collapsed: ref<boolean>(false),
      auth_user,
    };
  },
});
